/* Estilos Gerais */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  overflow-x: hidden;
  background-color: #031309;
  transition: opacity 0.5s ease-in-out;
}

a {
  color: white;
  text-decoration: none;
  transition: opacity 0.5s ease-in-out;
}

a:hover {
  text-decoration: underline;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
  bottom: 0;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
