body {
  margin: 0; 
  padding: 0; 
  overflow-x: hidden; 
  box-sizing: border-box; 
}



* {
  box-sizing: inherit; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
